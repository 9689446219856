import { getUserInfo } from "@stream/userInfo";

const replaceHotelDetailSecondaryTab = (pathname) => (typeof pathname !== 'string' ? '' : pathname).replace(/\/(home|price|location|introduction|review)\/*$/, '');

export const injectTeadsScript = (history) => {
  if (history && history?.page_location && history?.page_referrer) {
    try {
      const prv = new URL(history.page_referrer);
      const cur = new URL(history.page_location);
      const prvPath = replaceHotelDetailSecondaryTab(prv.pathname);
      const curPath = replaceHotelDetailSecondaryTab(cur.pathname);

      if (prvPath === curPath) throw new Error('in same hotel.');

      

      const $existingSlot = window.document.querySelector('div#teads-slot');
      if ($existingSlot) {
        const $newSlot = window.document.createElement('div');
        $newSlot.setAttribute('id', 'teads-slot');
        $newSlot.setAttribute('style', 'display: block;');
        $existingSlot.replaceWith($newSlot);
      }

    } catch(err) {
      return;
    }
  }

  const $existingScript = window.document.querySelector('script.teads');
  if ($existingScript) {
    $existingScript.remove();
  }
  
  let $teadsScript;
  let $teadsSlot;
  let cnt = 0;
  const intervalId = setInterval(() => {
    cnt = cnt + 1;
    if (cnt > 5) return clearInterval(intervalId);

    const userInfo = getUserInfo() || {};
    const channel = userInfo?.payload?.channel;

    let teadsScriptCodeNum;
    switch(channel) {
      case 'web': teadsScriptCodeNum = '215730'; break;
      case 'aos': teadsScriptCodeNum = '216773'; break;
      case 'ios': teadsScriptCodeNum = '216772'; break;
      default: return;
    }
    
    const teadsScriptSrc = `//a.teads.tv/page/${teadsScriptCodeNum}/tag`;

    $teadsSlot = window.document.querySelector('div#teads-slot');
    if (!$teadsSlot) return;
    $teadsScript = window.document.createElement('script');
    $teadsScript.setAttribute('type', 'text/javascript');
    $teadsScript.className = 'teads';
    $teadsScript.setAttribute('async', true);
    $teadsScript.setAttribute('src', teadsScriptSrc);
    window.document.body.appendChild($teadsScript);

    return clearInterval(intervalId);
  }, 100);
};
